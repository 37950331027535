import { Controller } from "@hotwired/stimulus";
import { Target, TypedController } from "@vytant/stimulus-decorators";

@TypedController
export default class extends Controller<HTMLDivElement> {
  @Target readonly countTarget!: HTMLSpanElement;

  public hide() {
    this.element.classList.add("hidden");
  }

  public show() {
    this.element.classList.remove("hidden");
  }

  selectAll() {
    const elem = document.querySelector<HTMLInputElement>("input[type='checkbox'][name='select_all']")!;
    elem.checked = true;
    elem.dispatchEvent(new Event("input"));
  }
}
