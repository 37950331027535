import { Target, Targets, TypedController, Value } from "@vytant/stimulus-decorators";
import { Controller } from "stimulus";

@TypedController
export default class extends Controller {
  @Target readonly communicationSubscriptionIdTarget: HTMLSelectElement;
  @Targets readonly propertyFieldTargets: HTMLInputElement[];

  @Value(String) actionNameValue: string;

  connect() {
    if (this.actionNameValue === "edit") {
      return;
    }
  }

  onCommunicationSubscriptionChange() {
    const selectedCommunicationSubscription = this.communicationSubscriptionIdTarget.selectedOptions[0];

    if (selectedCommunicationSubscription.innerHTML) {
      const downcasedCommunicationSubscriptionName = selectedCommunicationSubscription.innerHTML.toLowerCase().replace(/ /g, "_");

      this.propertyFieldTargets.forEach((propertyField) => {
        propertyField.parentElement?.parentElement?.classList.remove("tw-hidden");

        const propertyFieldName = propertyField.name.split("[")[1].split("]")[0].split("_property")[0];

        propertyField.value = `unidy__${downcasedCommunicationSubscriptionName}_${propertyFieldName}`;
      });
    }
  }
}
