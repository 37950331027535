import { Controller } from "@hotwired/stimulus";
import { TypedController, Target, Value } from "@vytant/stimulus-decorators";
import { createJSONEditor } from "vanilla-jsoneditor";

@TypedController
export default class extends Controller {
  @Value(String) readonly jsonValue: string;
  @Value(Boolean) readonly readOnlyValue: boolean;
  @Target inputTarget;
  private declare readonly hasInputTarget: boolean;

  connect() {
    const decodedValue = this.jsonValue.replace(/&quot;/g, '"');

    let jsonData = decodedValue === '"null"' ? "{}" : decodedValue;

    const editorProps: {
      content: { json: JSON; text: undefined };
      readOnly: boolean;
      navigationBar: boolean;
      onChange?: (updatedContent: any) => void;
    } = {
      content: { json: JSON.parse(jsonData), text: undefined },
      readOnly: this.readOnlyValue,
      navigationBar: false,
    };

    if (!this.readOnlyValue) {
      editorProps.onChange = this.handleChange.bind(this);
    }

    createJSONEditor({
      target: this.element,
      props: editorProps,
    });
  }

  handleChange(updatedContent: { json: JSON; text: undefined }) {
    if (this.hasInputTarget) {
      let contentToSave = updatedContent.json || updatedContent;
      this.inputTarget.value = JSON.stringify(contentToSave);
    }
  }
}
