import { Controller } from "@hotwired/stimulus";
import { Target, TypedController } from "@vytant/stimulus-decorators";

@TypedController
export default class extends Controller {
  @Target formTarget!: HTMLFormElement;

  private changes: Record<string, string> = {};

  connect() {
    this.formTarget.querySelectorAll("input,select,textarea").forEach((el: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement) => {
      el.addEventListener("input", (e) => {
        if (el.dataset.initial !== el.value) {
          this.changes[el.name] = el.value;
        } else {
          delete this.changes[el.name];
        }

        this.updateFooter();
      });
    });
  }

  submit() {
    this.formTarget.requestSubmit();
  }

  reset() {
    this.changes = {};
    this.updateFooter();
  }

  private updateFooter() {
    if (Object.keys(this.changes).length > 0) {
      this.formTarget.dataset.changes = "true";
    } else {
      this.formTarget.dataset.changes = "false";
    }
  }
}
