import { Target, TypedController, Value } from "@vytant/stimulus-decorators";
import { Controller } from "stimulus";
import { updateProps } from "../../../../frontend/lib/react/updateProps";

@TypedController
export default class NewsletterSubscriptionController extends Controller {
  @Target newsletterTarget?: HTMLSelectElement;
  readonly hasNewsletterTarget: boolean;

  @Target newsletterPreferencesTarget: HTMLDivElement;
  @Value(String) readonly newsletterPreferencesValue: string;

  @Target userSelectTarget: HTMLDivElement;
  @Target emailTarget: HTMLInputElement;

  connect() {
    this.element.closest("form")?.addEventListener("reset", () => {
      this.emailTarget.disabled = false;
      this.setUserInputDisabled(false);
      updateProps(this.newsletterPreferencesTarget, (p) => ({ ...p, disabled: true }));
      this.mountUserIdListener();
    });

    if (this.hasNewsletterTarget) {
      this.newsletterTarget!.addEventListener("change", () => {
        if (this.newsletterTarget!.value === "") {
          return updateProps(this.newsletterPreferencesTarget, (p) => ({ ...p, disabled: true }));
        }

        const newsletterId = Number(this.newsletterTarget!.value);
        const newOptions = this.newsletterPreferences[newsletterId] || [];

        updateProps(this.newsletterPreferencesTarget, (p) => ({ ...p, disabled: false, options: newOptions }));
      });
    }

    this.emailTarget.addEventListener("input", () => this.setUserInputDisabled(this.emailTarget.value !== ""));

    // Since React might have not rendered when this component is mounted
    setTimeout(() => this.mountUserIdListener(), 50);
  }

  private mountUserIdListener() {
    this.userIdInput.addEventListener("change", (e) => {
      this.emailTarget.disabled = this.userIdInput.value !== "";
      this.emailTarget.value = this.userIdInput.value ? "{Loaded from User}" : "";
    });
  }

  private get newsletterPreferences() {
    let parsed: { id: number; newsletter_preferences: { id: number; name: string }[] }[];

    try {
      parsed = JSON.parse(this.newsletterPreferencesValue);
    } catch (error) {
      return {};
    }

    return Object.fromEntries(parsed.map((ns) => [ns.id, ns.newsletter_preferences.map((np) => [np.name, np.id])]));
  }

  private setUserInputDisabled(disabled: boolean) {
    updateProps(this.userSelectTarget, (p) => ({ ...p, disabled, value: null }));
  }

  private get userIdInput() {
    return this.userSelectTarget.querySelector<HTMLInputElement>('input[type="hidden"]')!;
  }
}
