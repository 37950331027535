import { Application } from "@hotwired/stimulus";

const application = Application.start();

// @ts-ignore
const componentControllers = import.meta.globEager(
  "../../components/admin/**/(*_)?controller.@(ts|js)"
);

// @ts-ignore
const controllers = import.meta.globEager(
  "../controllers/admin/*_controller.{ts,js}"
);

export default application;
export { componentControllers, controllers };
