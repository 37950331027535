import { TypedController } from "@vytant/stimulus-decorators";
import { Controller } from "stimulus";

@TypedController
export default class extends Controller<HTMLDialogElement> {
  connect() {
    this.element.addEventListener("close", (event) => {
      this.form?.reset();
    });

    this.form?.addEventListener("submit", (event) => {
      const button = this.form!.querySelector<HTMLButtonElement>("button[type=submit]");

      if (button) {
        button.disabled = true;
        button.dataset.loading = "true";
        button.querySelector<HTMLSpanElement>("span.text")!.textContent = "Saving...";
      }
    });
  }

  private get form() {
    return this.element.querySelector<HTMLFormElement>("form");
  }
}
