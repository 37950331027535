import { Controller } from "@hotwired/stimulus";
import { Target, TypedController } from "@vytant/stimulus-decorators";

import type MultiselectToolbarController from "./multiselect_toolbar_controller";

@TypedController
export default class extends Controller<HTMLTableElement> {
  @Target readonly selectAllTarget!: HTMLInputElement;

  private lastSelectedIndex: number | null = null;

  static outlets = ["admin--dashboard--index-view--multiselect-toolbar"];
  private declare readonly adminDashboardIndexViewMultiselectToolbarOutlets: MultiselectToolbarController[];

  connect() {
    this.selectAllTarget.addEventListener("input", () => {
      this.all().forEach((checkbox) => {
        checkbox.checked = this.selectAllTarget.checked;
      });

      this.update();
    });

    this.element.querySelectorAll('tr > td:first-child input[type="checkbox"]').forEach((checkbox, index) => {
      checkbox.addEventListener("click", (event: MouseEvent) => {
        if (event.shiftKey) {
          const start = this.lastSelectedIndex ?? index;
          const end = index;

          this.all().forEach((checkbox, index) => {
            if (index >= start && index <= end) {
              checkbox.checked = true;
            }
          });
        }

        this.lastSelectedIndex = index;
        this.update();
      });
    });
  }

  private update() {
    const checkedCount = this.all(true).length;
    const totalCount = this.all().length;

    this.selectAllTarget.checked = checkedCount === totalCount;
    if (checkedCount === 0) {
      this.toolbar.hide();
    } else {
      this.toolbar.show();
    }

    this.toolbar.countTarget.textContent = checkedCount.toString();
  }

  private pagyData() {
    try {
      return JSON.parse(this.element.parentElement!.querySelector("#view-data")!.textContent!);
    } catch {
      return {};
    }
  }

  private all(checked = false) {
    return this.element.querySelectorAll<HTMLInputElement>(`tr > td:first-child input[type="checkbox"]${checked ? ":checked" : ""}`);
  }

  private get toolbar() {
    return this.adminDashboardIndexViewMultiselectToolbarOutlets[0]!;
  }
}
