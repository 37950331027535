import { Controller } from "@hotwired/stimulus";
import { Target, TypedController, Value } from "@vytant/stimulus-decorators";
import type { TurboFrame } from "~/types";

@TypedController
export default class extends Controller {
  @Target overlayTarget: HTMLDivElement;

  @Target drawerTarget: HTMLDivElement;
  @Target loadingTarget: HTMLDivElement;

  @Target titleTarget: HTMLDivElement;
  @Target linkTarget: HTMLAnchorElement;
  @Target linkOldTarget: HTMLAnchorElement;
  @Target deleteButtonTarget: HTMLFormElement;

  @Target actionsTarget: HTMLDetailsElement;
  declare readonly hasActionsTarget: boolean;

  @Value(String) readonly modelPathValue!: string;

  connect() {
    document.addEventListener("keydown", (event) => {
      if (event.key === "Escape") {
        this.closeShedit();
      }
    });

    document.addEventListener("close-shedit", () => {
      this.closeShedit();
    });
  }

  openShedit(event: any) {
    const target = (
      event.target instanceof HTMLAnchorElement
        ? event.target
        : event.target.closest("a")
    ) as HTMLAnchorElement;
    const id = target?.dataset.id;
    if (!id) {
      return;
    }

    const titleTemplate =
      target.parentElement?.querySelector<HTMLTemplateElement>(
        "template.shedit-header"
      );
    if (titleTemplate) {
      this.titleTarget.innerHTML = "";
      this.titleTarget.appendChild(titleTemplate.content.cloneNode(true));
    } else {
      this.titleTarget.textContent = "Ups...";
    }

    this.frameTarget.dataset.id = id;
    this.frameTarget.classList.add("hidden");
    this.frameTarget.src = `/dashboard/${this.modelPathValue}/${id}?frame=true`;

    if (this.hasActionsTarget && this.actionsFrame) {
      this.actionsTarget.classList.add("hidden");
      this.actionsFrame.src = `/dashboard/${this.modelPathValue}/${id}/actions`;
      this.actionsFrame.loaded.then(() => {
        this.actionsTarget.classList.remove("hidden");
      });
    }

    this.loadingTarget.classList.remove("hidden");
    this.drawerTarget.classList.remove("hidden");

    // update URLs
    const tmpUrl = new URL(window.location.href);
    tmpUrl.searchParams.set("id", id);
    window.history.replaceState(null, "", tmpUrl.toString());
    this.linkTarget.href = `/dashboard/${this.modelPathValue}/${id}`;
    this.linkOldTarget.href = `/admin/${this.modelPathValue}/${id}`;
    this.deleteButtonTarget.action = `/dashboard/${this.modelPathValue}/${id}?source=shedit`;

    // on load remove loader and show frame
    this.frameTarget.loaded.then(() => {
      this.frameTarget.classList.remove("hidden");
      this.loadingTarget.classList.add("hidden");
    });

    this.overlayTarget.classList.remove("hidden");
  }

  closeShedit() {
    this.drawerTarget.classList.add("hidden");

    const tmpUrl = new URL(window.location.href);
    tmpUrl.searchParams.delete("id");
    tmpUrl.searchParams.delete("tab");
    tmpUrl.searchParams.delete("history_page");
    tmpUrl.searchParams.delete("history_per_page");

    window.history.replaceState(null, "", tmpUrl.toString());

    this.overlayTarget.classList.add("hidden");
  }

  private get frameTarget() {
    return this.element.querySelector<TurboFrame>("#shedit-frame")!;
  }

  private get actionsFrame() {
    return this.actionsTarget.querySelector<TurboFrame>("#shedit-actions")!;
  }
}
