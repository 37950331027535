import camelCase from "lodash-es/camelCase";

// biome-ignore lint/complexity/noBannedTypes: <explanation>
export const updateProps = <K = {}>(target: HTMLDivElement, updateProps: (p: K) => K) => {
  const controller = target.dataset.controller;
  if (!controller) {
    throw new Error("Missing controller");
  }

  const propsName = `${camelCase(controller)}PropsValue`;

  const oldProps = target.dataset[propsName];
  if (!oldProps) {
    throw new Error("Missing props");
  }

  const props = JSON.parse(oldProps);
  const newProps = updateProps(props);

  target.dataset[propsName] = JSON.stringify(newProps);
};
