import { Controller } from "@hotwired/stimulus";
import { TypedController } from "@vytant/stimulus-decorators";

@TypedController
export default class extends Controller {
  connect() {
    this.element.querySelectorAll("#tabs input").forEach((input) => {
      input.addEventListener("change", this.changeTab);
    });
  }

  changeTab(event: MouseEvent) {
    // @ts-ignore
    const tab = event.target?.value;
    if (!tab) return;

    const url = new URL(window.location.href);
    url.searchParams.set("tab", tab);

    window.history.replaceState(null, "", url.toString());

    const openLink = document.querySelector<HTMLAnchorElement>('[data-admin--dashboard--index-view-target="link"]');
    if (openLink) {
      const tmp = new URL(openLink.href);
      tmp.searchParams.set("tab", tab);
      openLink.href = tmp.toString();
    }
  }
}
