import { Controller } from "@hotwired/stimulus";
import { Target, TypedController } from "@vytant/stimulus-decorators";

// TODO: Extract this functionality to a different controller (probably in form_view)
@TypedController
export default class extends Controller {
  @Target
  private editButtonTarget: HTMLAnchorElement;

  connect() {
    this.editButtonTarget.addEventListener("click", (event) =>
      this.openDialog(
        event,
        this.editButtonTarget,
        this.dialog,
        `form-${this.editButtonTarget.dataset.model}-${this.editButtonTarget.dataset.id}`
      )
    );
  }

  private openDialog(
    event: Event,
    button: HTMLAnchorElement,
    dialog: HTMLDialogElement,
    frameId: string
  ) {
    event.preventDefault();
    const url = new URL(button.href);
    url.searchParams.set("frame", "true");

    dialog.innerHTML = `
      <turbo-frame id="${frameId}" src="${url}">
        <h1 class="text-3xl text-red-400">LOADING... TODO MAKE THIS NICE</h1>
      </turbo-frame>
    `;
    dialog.showModal();
  }

  private get dialog() {
    return document.querySelector<HTMLDialogElement>("#edit-dialog")!;
  }
}
